/**
 * Module dependencies.
 */

import { ErrorPage } from 'src/components/pages/error';
import { Settings } from 'src/api/entities/settings/types';
import { fetchSettingsAgnostic } from 'src/api/entities/settings/queries';
import { getAllPageSlugsFlat } from 'src/api/entities/pages/slug/queries';
import { pageStatus } from 'src/core/constants/pages';

/**
 * `Props` type.
 */

type Props = {
  settings?: Settings;
  type?: 'notFound';
};

/**
 * Constants.
 */

const revalidate = Number(process.env.NEXT_REVALIDATE_INTERVAL);

/**
 * Export 404 page.
 */

export default function Page404(props: Props) {
  if ('type' in props && props?.type === 'notFound' && props?.settings?.regionSettings?.errorPage404) {
    return <ErrorPage {...props.settings.regionSettings.errorPage404} />;
  }

  return (
    <ErrorPage
      ctaFirst={{
        href: '/',
        label: 'Go back home'
      }}
      sections={[]}
      translations={{
        marquee: 'Oops!',
        title: 'An error occurred on the server'
      }}
    />
  );
}

/**
 * `getStaticProps`.
 */

export const getStaticProps = async () => {
  const props = {
    page: {},
    type: 'notFound'
  };

  try {
    const slugs = (await getAllPageSlugsFlat({ status: pageStatus })) ?? [];
    const settings = await fetchSettingsAgnostic();

    return {
      props: {
        ...props,
        settings: settings ?? {},
        slugs
      },
      revalidate
    };
  } catch {
    return {
      props: {
        ...props,
        settings: {},
        slugs: []
      },
      revalidate
    };
  }
};
