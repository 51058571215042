/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Container } from 'src/components/core/layout/container';
import { ErrorPage as ErrorPageProps } from 'src/api/entities/error-pages/types';
import { Marquee } from 'src/components/sections/lists/shared/marquee';
import { RawHtml } from 'src/components/core/raw-html';
import { Section } from 'src/components/sections';
import { Text } from 'src/components/core/text';
import { media } from 'src/styles/media';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = ErrorPageProps;

/**
 * `ErrorWrapper` styled component.
 */

const ErrorWrapper = styled.section`
  color: var(--color-neutral0);
  display: flex;
  height: 100svh;
  justify-content: center;
  position: relative;
`;

/**
 * `StyledMarquee` styled component.
 */

const StyledMarquee = styled(Marquee)`
  inset: 0;
  position: absolute;
  transform: translateY(33%) !important;
`;

/**
 * `ErrorContent` styled component.
 */

const ErrorContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 45vh;
  position: relative;

  ${media.min.ms`
    margin-top: 55vh;
  `}
`;

/**
 * `TextWrapper` styled component.
 */

const TextWrapper = styled.div`
  display: grid;
  max-width: 584px;
  row-gap: 16px;
  text-align: center;
`;

/**
 * `CtaWrapper` styled component.
 */

const CtaWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;

  ${media.min.ms`
    flex-direction: row;
    justify-content: center;
  `}
`;

/**
 * Export `ErrorPage` component.
 */

export const ErrorPage = ({ ctaFirst, ctaSecond, sections, translations: { marquee, subtitle, title } }: Props) => (
  <>
    <ErrorWrapper data-theme={'dark'}>
      <StyledMarquee text={marquee} />

      <ErrorContainer>
        <TextWrapper>
          <RawHtml element={Text} elementProps={{ block: true, fontWeight: 700, variant: 'heading2' }}>
            {title}
          </RawHtml>

          <RawHtml element={Text} elementProps={{ block: true, variant: 'paragraph1' }}>
            {subtitle}
          </RawHtml>
        </TextWrapper>

        <CtaWrapper>
          {ctaFirst?.href && ctaFirst?.label && (
            <Button aria-label={ctaFirst.label} hasLinkIcon href={ctaFirst?.href} size={'large'} variant={'primary'}>
              {ctaFirst.label}
            </Button>
          )}

          {ctaSecond?.href && ctaSecond?.label && (
            <Button
              aria-label={ctaSecond.label}
              data-theme={'dark'}
              hasLinkIcon
              href={ctaSecond.href}
              size={'large'}
              variant={'secondary'}
            >
              {ctaSecond.label}
            </Button>
          )}
        </CtaWrapper>
      </ErrorContainer>
    </ErrorWrapper>

    {sections?.map(section => <Section key={section.id} {...section} />)}
  </>
);
